<template>
  <div>
    <el-dialog title="选择处方" :visible.sync="info.isShow" width="60%" top="10vh">
      <div>
          <div class="F tip">
            <div class="line"></div>
            <div>处方模板</div>
          </div>
          <el-row style="margin: 10px;">
            <el-col :span="6">
                <el-input
                v-model="searcForm.param"
                placeholder="搜索处方名称/编号"
              >
                <i
                 slot="suffix"
                  @click="getPrescribe()"
                  class="el-input__icon el-icon-search"
                ></i>
              </el-input>
            </el-col>
          
            <el-col :span="18" style="text-align:right;color:#409EFF;">
              <span  style="cursor:pointer;" @click="addPrescribe()">新增处方 <i
                  slot="prefix"
                  class="el-input__icon el-icon-plus"
                ></i></span>
            </el-col>
          </el-row>
          <div>
            <el-table
              height="30vh"
              v-loading="loading"
              style="margin: 0px 10px; width: 97%"
              border
              ref="multipleTable"
              :data="prescribeList"
              tooltip-effect="dark"
              @selection-change="handleSelectionChange"
              row-key="id"
            >
              <el-table-column
                align="center"
                :reserve-selection="true"
                type="selection"
                width="55"
              ></el-table-column>
             
              <el-table-column align="center" label="处方名称" prop="name"
                ><template slot-scope="scope"
                  ><div style="text-align: left">
                    {{ scope.row.name ? scope.row.name : "-" }}
                  </div></template
                ></el-table-column
              >
              <el-table-column align="center" label="处方说明" prop="description"
                ><template slot-scope="scope"
                  ><div style="text-align: left">
                    {{ scope.row.description ? scope.row.description : "-" }}
                  </div></template
                ></el-table-column
              >
              
            </el-table>
        </div>
      </div>
      <div>
        <div class="F tip">
          <div class="line"></div>
          <div>临时处方 <span style="color:#999">(* 临时处方仅用于本次开单)</span></div>
        </div>
         <div>
            <el-table
              height="20vh"
              v-loading="loading"
              style="margin: 0px 10px; width: 97%"
              border
              
              :data="tempPrescribeList"
              tooltip-effect="dark"
              row-key="id"
            >
             <el-table-column align="center" label="操作" prop=""  width="55">
                <template slot-scope="scope">
                  <el-button type="text" style="color:#f56c6c" @click="DeleteData(scope.row)"
                 >删除</el-button>
                </template>
                 
              </el-table-column>
              <el-table-column align="center" label="处方名称" prop="name"
                ><template slot-scope="scope"
                  ><div style="text-align: left">
                    {{ scope.row.name ? scope.row.name : "-" }}
                  </div></template
                ></el-table-column
              >
              <el-table-column align="center" label="处方说明" prop="description"
                ><template slot-scope="scope"
                  ><div style="text-align: left">
                    {{ scope.row.description ? scope.row.description : "-" }}
                  </div></template
                ></el-table-column
              >
              
            </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="info.isShow = false">取 消</el-button>
        <el-button type="primary" @click="submitData">确 定</el-button>
      </span>
    </el-dialog>
    <add-prescribe :info="addInfo" @data="refreshPrescribe"></add-prescribe>
  </div>
</template>

<script>
import { GetPrescribeList,DeletePrescribe } from "@/api/prescribe";
import addPrescribe from "@/components/SelectPrescribe/addPrescribe";
export default {
  components:{addPrescribe},
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      prescribeList: [],
      loading: false,
      GoodsTypeList: [],
      defaultProps: {
        children: "list",
        label: "name",
      },
      addInfo:{
        isShow:false
      },
      searcForm: {
        pageNum: 1,
        pageSize: 10,
        param: "",
        typeId: null,
      },
      total: 0,
      tempPrescribeList:[],
      multipleSelection:[]
    };
  },
  created() {
    this.getPrescribe();
    this.tempPrescribeList = JSON.parse(sessionStorage.getItem("prescribes")) || [];
  },
  methods: {
    // 获取客户详情
    async getPrescribe() {
      let params = this.searcForm;
      let res = await GetPrescribeList(params);
      if (res.code == 1) {
        this.prescribeList = res.data.records;
        this.total = res.data.total;
      }
    },
   setSelection(ids){
        if(ids&&ids.length>0){
          this.$nextTick(()=>{
          const selectes = this.prescribeList.filter(v=>ids.indexOf(v.id)>-1);
          this.$refs.multipleTable.clearSelection();
           selectes.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
         });
          
        }
      
      
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    DeleteData(data) {
      this.$confirm(`是否删除临时处方？`, "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(() => {
        DeletePrescribe(data.id).then((res) => {
          const { code, msg } = res;
          if (code === 1) {
            this.$message.success(msg);
            this.tempPrescribeList  = this.tempPrescribeList.filter(v=>v.id!=data.id);
            sessionStorage.setItem("prescribes",JSON.stringify(this.tempPrescribeList));
          } else {
            this.$message.error(msg);
          }
        });
      });
    },
    clickNode(data, node) {
      if (data.id) {
        this.searcForm.typeId = data.id;
        this.getCustomer();
      } else {
        this.searcForm.typeId = null;
        this.getCustomer();
      }
    },
    addPrescribe(){
      this.addInfo.isShow = true;
    },
    refreshPrescribe(){
      this.getPrescribe();
      // 获取最新的临时处方
      this.tempPrescribeList = JSON.parse(sessionStorage.getItem("prescribes"));
    },
    submitData() {
      if(this.multipleSelection.length>0 ||  this.tempPrescribeList.length>0){
       this.$emit("SelectPrescribe", [...this.multipleSelection,...this.tempPrescribeList]);
      this.info.isShow = false;
      }else{
        this.$message.error("请选择处方");
      }
      
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-tree-node:focus > .el-tree-node__content {
  background-color: #f0f7ff !important;
}
/deep/ .el-dialog__body{
  padding-top: 10px;
}
.treeContent {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: start;
  .left,
  .right {
    height: 100%;
    border: 1px solid gray;
  }
  .left {
    width: 20%;
    border-right: 0px;
    overflow: auto;
  }
  .right {
    width: 80%;
    position: relative;
    .pagination {
      width: 100%;
      text-align: center;
      margin-top: 10px;
      position: absolute;
      bottom: 10px;
    }
  }
}
.tip {
  height: 40px;
  line-height: 40px;
  font-weight: bold;
  margin-bottom: 10px;
}
.line {
  height: 20px;
  width: 4px;
  background: #35c093;
  margin-top: 10px;
  margin-right: 10px;
}
.F {
  display: flex;
}
</style>