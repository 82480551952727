<template>
  <div class="view-wrap">
    <el-form :model="searchForm" class="F">
      <el-form-item label="业务日期:" style="width: 230px">
        <el-date-picker
          v-model="searchForm.time"
          type="datetime"
          placeholder="选择日期时间"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          style="width: 150px"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="出库仓库:" style="width: 230px">
        <el-select
          v-model="searchForm.wmsId"
          placeholder=""
          clearable
          style="width: 150px"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="item in wmsList"
            :key="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="客户:"
        style="width: 200px; margin-right: 15px"
        label-width="60px"
      >
        <div style="position: relative" @click="customClick">
          <el-input v-model="checkCustomName"></el-input>
          <span
            style="position: absolute; right: 10px; top: -3px; cursor: pointer"
            >...</span
          >
        </div>
      </el-form-item>

      <el-form-item label="当前欠款:" style="width: 140px">
        <span style="color: #f00">{{
          payment ? (payment / 100).toFixed(2) : "0.00"
        }}</span
        >元
      </el-form-item>
      <el-form-item
        label="处方:"
        style="width: 250px; margin-right: 15px"
        label-width="60px"
      >
        <div style="position: relative" @click="prescribeClick">
          <el-input v-model="checkPrescribeName"></el-input>
          <span
            style="position: absolute; right: 10px; top: -3px; cursor: pointer"
            >...</span
          >
        </div>
      </el-form-item>
      <el-form-item
        label="单据编号:"
        style="width: 230px; margin-right: 15px"
        label-width="80px"
      >
        <el-input
          type="text"
          placeholder=""
          v-model="searchForm.no"
          disabled
          style="width: 160px"
        ></el-input>
      </el-form-item>

      <el-form-item label="制单人:" style="width: 230px">
        <el-select
          v-model="searchForm.handler"
          placeholder=""
          clearable
          disabled
          style="width: 160px"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="item in userCustomList"
            :key="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="proContent F">
      <div class="con-left" v-if="selectFlag">
        <el-tree
          :data="tableData"
          :props="defaultProps"
          accordion
          :default-expand-all="true"
          @node-click="handleNodeClick"
        >
        </el-tree>
      </div>
      <div class="con-right" v-if="selectFlag">
        <ul class="FS bigbox" style="flex-wrap: wrap">
          <li
            class="right-item"
            v-for="item in productData"
            :key="item.id"
            @click="openProductInfo(item)"
          >
            <div
              class="right-line"
              :class="!item.num ? 'soldOut-line' : ''"
            ></div>
            <div class="FSB item-title">
              <div class="item-name">{{ item.name }}</div>
              <div class="item-no">{{ item.code }}</div>
            </div>
            <div class="item-desc F">
              <div class="item-icon">
                <div class="icon-circle"></div>
                <div class="icon-line"></div>
                <div class="icon-circle"></div>
              </div>
              <div class="desc-con">
                <div class="con-inventroy F">
                  <div class="con-wid">库存</div>
                  <div class="con-num">{{ item.inventory }}</div>
                </div>
                <div class="con-inventroy F">
                  <div class="con-wid">登记证号</div>
                  <div class="con-no">{{ item.regCode }}</div>
                </div>
              </div>
              <div class="limit-img" v-if="item.isLimit == '1'">
                <img
                  src="@/assets/image/limit.png"
                  alt=""
                  style="width: 22px; height: 25px"
                />
              </div>
              <div
                class="limit-img"
                v-if="Config.isMinus == '0' && !item.inventory"
              >
                <img
                  src="@/assets/image/soldOut.png"
                  alt=""
                  style="width: 61px; height: 48px"
                />
              </div>
            </div>
            <div class="money">
              <span class="money-icon">&yen;</span>
              <span v-if="item.tempPrice" class="money-num">{{
                item.tempPrice
              }}</span>
              <span v-else class="money-num">{{
                item.price != undefined ? item.price.toFixed(2) : "0.00"
              }}</span>
              <div
                class="add-icon"
                @click.stop="addProduct(item)"
                v-if="Config.isMinus == '1' || item.inventory"
              >
                <img
                  src="@/assets/image/add.png"
                  alt=""
                  style="width: 60px; height: 48px"
                />
              </div>
            </div>
          </li>
        </ul>
        <!-- 将background设置为true便可以修改分页的背景样式 -->
        <div style="display: flex; justify-content: center; margin-top: -10px">
          <el-pagination
            v-show="total > 0"
            class="msg-pagination-container"
            :background="isBackground"
            layout="prev, pager, next"
            @current-change="changeCurrentPage"
            :current-page="searchForm.pageNum"
            :page-size="searchForm.pageSize"
            :total="total"
          />
        </div>
      </div>
      <!-- 已选商品 -->
      <div class="selectedProduct" v-if="!selectFlag">
        <div class="FSB selected-title">
          <div>已选商品</div>
          <div>
            <el-button plain size="mini" @click="closeSelected">关闭</el-button>
          </div>
        </div>
        <el-table
          :data="productList"
          height="60vh"
          border
          style="width: 100%"
          :cell-class-name="tableRowClassName"
          @cell-click="handleRowClick"
          show-summary
          :summary-method="getSummaries"
          v-if="tabelFlag"
        >
          <el-table-column label="序号" width="80" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="removeList(scope.$index)"
                >删除</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="copyList(scope.row.id, scope.$index)"
                >复制</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="name"
            label="商品名称"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="code"
            label="商品编号"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="inventory"
            label="当前库存"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="name"
            label="单位"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                v-if="
                  scope.row.index === clickRowIndex &&
                  tabClickLabel == scope.column.label
                "
              >
                <el-select
                  placeholder="请选择"
                  v-model="scope.row.unitId"
                  @change="unitChange(scope.row)"
                >
                  <el-option
                    v-for="item in unitData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div
                v-else
                @click="unitClick(scope.row.unitList)"
                style="width: 100%; cursor: pointer"
              >
                {{ getUnitName(scope.row.unitList, scope.row.unitId) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="buyNum"
            label="数量"
            show-overflow-tooltip
            min-width="115px"
          >
            <template slot-scope="scope">
              <div
                v-if="
                  scope.row.index === clickRowIndex &&
                  tabClickLabel == scope.column.label
                "
              >
                <el-input-number
                  size="mini"
                  controls-position="right"
                  v-model="scope.row.buyNum"
                  @change="buyNumChange(scope.column)"
                  :min="1"
                  :max="scope.row.inventroyNum"
                ></el-input-number>
              </div>
              <span v-else>{{ scope.row.buyNum }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="price"
            label="单价"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                v-if="
                  scope.row.index === clickRowIndex &&
                  tabClickLabel == scope.column.label
                "
                style="position: relative"
              >
                <el-input-number
                  size="mini"
                  :controls="false"
                  v-model="scope.row.tempPrice"
                  @blur="inputBlur(scope.row)"
                ></el-input-number>
                <span
                  style="
                    position: absolute;
                    top: 10%;
                    right: 5px;
                    cursor: pointer;
                  "
                  @click="openPriceList(scope.row, scope.$index)"
                  >···</span
                >
              </div>
              <span v-else>
                <span
                  :class="
                    scope.row.priceType == '零售价'
                      ? 'priceActive'
                      : scope.row.priceType == '最新采购价'
                      ? 'priceActive2'
                      : scope.row.priceType == '参考采购价'
                      ? 'priceActive2'
                      : scope.row.priceType == '最新售价'
                      ? 'priceActive2'
                      : scope.row.priceType == '批发价'
                      ? 'priceActive3'
                      : scope.row.priceType == '会员等级1'
                      ? 'priceActive4'
                      : scope.row.priceType == '会员等级2'
                      ? 'priceActive4'
                      : scope.row.priceType == '会员等级3'
                      ? 'priceActive4'
                      : scope.row.priceType == '会员等级4'
                      ? 'priceActive4'
                      : scope.row.priceType == '会员等级5'
                      ? 'priceActive4'
                      : ''
                  "
                  >{{ scope.row.priceType }}</span
                >
                {{ scope.row.tempPrice }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="金额"
            show-overflow-tooltip
            prop="jine"
          >
            <template slot-scope="scope">
              {{ (scope.row.buyNum * scope.row.tempPrice).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="discount"
            label="折扣率(%)"
            show-overflow-tooltip
            min-width="115px"
          >
            <template slot-scope="scope">
              <div
                v-if="
                  scope.row.index === clickRowIndex &&
                  tabClickLabel == scope.column.label
                "
              >
                <el-input-number
                  size="mini"
                  controls-position="right"
                  v-model="scope.row.discount"
                  @change="buyNumChange"
                  :min="0"
                  :max="100"
                  :disabled="scope.row.isGifts == '1'"
                ></el-input-number>
              </div>
              <span v-else>{{
                scope.row.discount ? scope.row.discount + "%" : "0%"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="折后单价"
            show-overflow-tooltip
            prop="zhehouPrice"
          >
            <template slot-scope="scope">
              {{
                ((scope.row.tempPrice * scope.row.discount) / 100).toFixed(2)
              }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="折后金额"
            show-overflow-tooltip
            prop="zhehoujine"
          >
            <template slot-scope="scope">
              {{
                (
                  (scope.row.buyNum *
                    scope.row.tempPrice *
                    scope.row.discount) /
                  100
                ).toFixed(2)
              }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="折扣优惠"
            show-overflow-tooltip
            prop="discountYouhui"
          >
            <template slot-scope="scope">
              {{
                (
                  scope.row.buyNum * scope.row.tempPrice -
                  (scope.row.buyNum *
                    scope.row.tempPrice *
                    scope.row.discount) /
                    100
                ).toFixed(2)
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="isGifts" label="赠品">
            <template slot-scope="scope">
              <input
                type="checkbox"
                v-model="scope.row.isGifts"
                @change="
                  checkboxChange(scope.$index, scope.row.isGifts, scope.row)
                "
              />
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="remark"
            label="备注"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                v-if="
                  scope.row.index === clickRowIndex &&
                  tabClickLabel == scope.column.label
                "
              >
                <el-input
                  size="small"
                  v-model="scope.row.remark"
                  @blur="inputBlur(scope.row)"
                ></el-input>
              </div>
              <span v-else>{{ scope.row.remark }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="footer FSB">
      <div class="footer-left F">
        <div class="footer-button" @click="selectedProduct">
          <img
            src="@/assets/image/shopcar.png"
            alt=""
            style="
              width: 18px;
              height: 18px;
              margin-right: 6px;
              vertical-align: text-bottom;
            "
          />
          <span class="btn-text">已选商品</span>
        </div>
        <div class="footer-text">
          <span class="species">商品种类</span>
          <span class="footer-num">{{
            productList ? productList.length : 0
          }}</span>
          <span class="species-unit">种</span>
        </div>
        <div>
          <span class="species">合计金额</span>
          <span class="footer-num">{{
            totalPrice != undefined ? totalPrice.toFixed(2) : "0.00"
          }}</span>
          <span class="species-unit">元</span>
        </div>
      </div>
      <div class="footer-right F">
        <div class="grayBtn" @click="discountRate">整单折扣率</div>
        <div class="grayBtn" @click="resetList">清空单据</div>
        <div class="saveBtn" @click="saveCaogao">存草稿</div>
        <div class="saveBtn" @click="saveData">结 算</div>
      </div>
    </div>
    <!-- 结算 -->
    <el-dialog title="结算" :visible.sync="payShow" width="30%">
      <div class="FSB">
        <div></div>
        <el-checkbox v-model="payFlag" @change="checkChange"
          >自定义收款</el-checkbox
        >
      </div>
      <div>
        <span class="sum textbold">本单合计：</span>
        <span class="textbold">{{
          totalPrice ? totalPrice.toFixed(2) : "0.00"
        }}</span>
      </div>
      <el-form :model="payForm" :rules="payRules" ref="payForm">
        <el-form-item
          label="收款金额："
          label-width="110px"
          prop="name"
          v-if="payFlag"
        >
          <el-input
            v-model="payForm.name"
            placeholder="请输入"
            @change="payChange"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="paySave(0)" v-if="!payFlag">全部挂账</el-button>
        <el-button type="primary" @click="paySave(1)">全部收款</el-button>
      </div>
    </el-dialog>
    <!-- 整单折扣率 -->
    <el-dialog title="整单折扣率" :visible.sync="discountShow" width="30%">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="整单折扣率：" label-width="110px" prop="name">
          <el-input
            v-model="form.name"
            placeholder="例如95折，输入数字95"
            @change="discountChange"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="discountShow = false">取 消</el-button>
        <el-button type="primary" @click="discountSave">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 价格参照dialog -->
    <el-dialog title="价格参照" :visible.sync="dialogVisible" width="40%">
      <div slot="title" class="dialog-title">
        <span style="display: inline-block; margin-right: 6px">价格参照</span>
        <el-tooltip
          class="item"
          effect="light"
          content="您可以选择任意参照价格，直接设置为单价"
          placement="right"
        >
          <i class="el-icon-question"></i>
        </el-tooltip>
      </div>
      <div>
        <div class="proName">名称：名称</div>
        <div class="proUnit">单位：箱</div>
        <div class="FSB" style="flex-wrap: wrap">
          <div
            class="priceItem"
            v-for="(item, index) in priceList"
            :key="index"
            @click="priceClick(item)"
          >
            <div class="price">{{ item.value.toFixed(2) }}</div>
            <div class="priceType">{{ item.label }}</div>
          </div>
        </div>
      </div>
      <div class="F" style="margin-top: 15px">
        <div class="line"></div>
        <div>会员价</div>
      </div>
      <div class="FSB" style="flex-wrap: wrap">
        <div
          class="priceItem"
          v-for="(item, index) in vipList"
          :key="index"
          @click="vipPriceClick(item)"
        >
          <div class="price">{{ (item.price / 100).toFixed(2) }}</div>
          <div class="priceType">{{ item.label }}</div>
        </div>
      </div>
    </el-dialog>
    <ProductInfo :info="proInfo"></ProductInfo>
    <SelectCustom
      ref="selectCustomRef"
      :info="cusInfo"
      @SelectCustom="SelectCustom"
    ></SelectCustom>
    <SelectPrescribe
      ref="selectPrescribeRef"
      :info="prescribeInfo"
      @SelectPrescribe="SelectPrescribe"
    ></SelectPrescribe>
    <!-- 多个付款账户 -->
    <miaDialog width="30%" ref="multiAccount" v-if="multiAccountVisible">
      <div slot="content">
        <el-table :data="accountList" border>
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column property="name" label="账户"></el-table-column>
          <el-table-column property="address" label="付款金额">
            <template slot-scope="scope">
              <el-input-number
                size="mini"
                v-model="scope.row.price"
                :controls="false"
                :min="0"
                :max="99999"
                :precision="2"
                placeholder="请录入"
              ></el-input-number>
            </template>
          </el-table-column>
        </el-table>
        <span v-if="handlePrice">应收金额：￥{{ handlePrice.toFixed(2) }}</span>
        <div style="text-align: right; margin-top: 20px">
          <el-button @click="closeAccountDialog">取消</el-button>
          <el-button type="primary" @click="saveAccount">保存</el-button>
        </div>
      </div>
    </miaDialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GetProductTypeList } from "@/api/productType";
import { GetAccountList } from "@/api/account";
import { getCode, setWebOrder, getSaleDetails } from "@/api/sale";
import { GetWmsList } from "@/api/warehouse";
import {
  getProductParamList,
  getDetails,
  getPriceReference,
} from "@/api/pesticideProduct";
import { getUserCustom, getTemplate } from "@/api/common";
import ProductInfo from "@/components/ProductInfo";
import SelectCustom from "@/components/SelectCustom";
import SelectPrescribe from "@/components/SelectPrescribe";
import moment from "moment";
import { GetParameter } from "@/api/system";
export default {
  computed: {
    ...mapState("user", ["userName", "name"]),
  },
  components: {
    ProductInfo,
    SelectCustom,
    SelectPrescribe,
  },
  data() {
    return {
      id: "",
      tabelFlag: true,
      priceDialogId: "",
      priceDialogIndex: "",
      payment: 0,
      priceList: [],
      vipList: [], //会员价列表
      newIndex: 0,
      productDetail: {},
      dialogVisible: false,
      clickRowIndex: null,
      tabClickLabel: "",
      selectFlag: true,
      preferentialPrice: 0,
      discount: 0,
      // handlePrice: 0,
      thisPayment: 0,
      accountId: "",
      // totalPrice: 0,
      userCustomList: [],
      productData: [],
      wmsList: [],
      productList: [],
      tableData: [],
      total: 0,
      isBackground: true,
      defaultProps: {
        children: "list",
        label: "name",
      },
      cusInfo: {
        isShow: false,
      },
      prescribeInfo: {
        isShow: false,
      },
      checkCustomName: "",
      searchForm: {
        pageNum: 1,
        pageSize: 12,
        time: "",
        customerId: "",
        no: "",
        wmsId: "",
        handler: "",
      },
      proInfo: {
        isShow: false,
        productInfo: {},
        tableData: [],
      },
      discountShow: false,
      payShow: false,
      payFlag: false,
      form: {
        name: "",
      },
      payForm: {
        name: "",
      },
      rules: {
        name: [{ required: true, message: "请输入折扣率", trigger: "blur" }],
      },
      payRules: {
        name: [{ required: true, message: "请输入收款金额", trigger: "blur" }],
      },
      Config: {},
      accountList: [], // 账户列表
      multiAccountVisible: false,
      submitProductList: [], // 要提交到后台的购物车数据
      checkPrescribeName: "", // 已选择处方
      prescriptionList: [], // 已选择处方列表
    };
  },
  computed: {
    totalPrice() {
      let total = 0;
      this.productList.forEach((item) => {
        if (item.discount) {
          total +=
            (item.tempPrice * 100 * item.buyNum * item.discount) / 100 / 100;
        } else {
          total += (item.tempPrice * 100 * item.buyNum) / 100;
        }
      });
      return total;
    },
    handlePrice() {
      let total = 0;
      this.productList.forEach((item) => {
        if (item.discount) {
          total +=
            (item.tempPrice * 100 * item.buyNum * item.discount) / 100 / 100;
        } else {
          total += (item.tempPrice * 100 * item.buyNum) / 100;
        }
      });
      return total;
    },
  },
  filters: {
    backPrice(val) {
      if (val == null || val == "NaN") {
        return "";
      }
      let num = val;
      num += "";
      num = num.replace(/[^0-9|\.]/g, "");

      if (/^0+/) num = num.replace(/^0+/, "");
      if (!/\./.test(num)) num += ".00";
      if (/^\./.test(num)) num = "0" + num;
      num += "00";
      num = num.match(/\d+\.\d{2}/)[0];
      return num;
    },
  },
  created() {
    // 如果有临时处方，那么清空
    sessionStorage.removeItem("prescribes");

    this.searchForm.time = moment().format("YYYY-MM-DD");
    this.GetConfig();
    this.getType();
    this.getCode();
    this.GetWmsList();
    this.getUserCustom();
    this.GetAccountList();
    this.getTemplate();
    this.id = this.$route.query.id;
    if (this.id) {
      this.getSaleDetails();
    }
  },
  methods: {
    async getSaleDetails() {
      let res = await getSaleDetails({ id: this.id });
      if (res.code == 1) {
        //  this.saleInfo = res.data;
        this.prescriptionList = res.data.prescriptionList;
        this.checkPrescribeName = res.data.prescriptionList
          .map((v) => v.name)
          .join(",");
        this.checkCustomName = res.data.customerName;
        this.searchForm.customerId = res.data.customerId;
        this.searchForm.time = res.data.entryDate;
        this.searchForm.wmsId = res.data.wmsId;
        this.payment = res.data.customerPayment;
        // 处理购物车商品
        this.productList = res.data.productList.map((item) => {
          item.price = item.price / 100;
          item.tempPrice = item.price;
          item.buyNum = item.num;
          return item;
        });
        console.log(this.productList);
        this.priceSum();
      }
    },
    // 打开多账户付款
    openAccountDialog() {
      this.multiAccountVisible = true;
      this.$nextTick(() => {
        this.$refs.multiAccount.init("结算");
      });
    },
    // 关闭多账户窗口
    closeAccountDialog() {
      this.$refs.multiAccount.close();
    },
    async saveAccount() {
      let total = 0;
      const accountList = this.accountList
        .filter((a) => a.price != undefined)
        .map((a) => {
          total += a.price * 100;
          return { accountId: a.id, price: a.price * 100 };
        });
      let priceTotal = 0; // 应收金额(不计算折扣)
      this.submitProductList = this.submitProductList.map((item) => {
        delete item.discountPrice;
        item.price = item.price * 100;
        priceTotal += item.price * item.num;
        return item;
      });
      let params = {
        customerId: this.searchForm.customerId,
        status: 0,
        wmsId: this.searchForm.wmsId,
        handler: this.searchForm.handler,
        entryDate: this.searchForm.time,
        no: this.searchForm.no,
        price: priceTotal,
        preferentialPrice: Number(
          ((this.handlePrice - this.totalPrice) * 100).toFixed(2)
        ), //优惠金额
        discount: this.discount, // 折扣
        handlePrice: this.handlePrice * 100, //应收金额
        thisPayment: total, //本次收款
        accountList: accountList, // 付款账户
        productList: this.submitProductList,
        prescriptionList: this.prescriptionList,
      };

      let res = await setWebOrder(params);
      if (res.code == 1) {
        this.$message({
          type: "success",
          message: res.msg,
        });
        this.resetList();
        this.closeAccountDialog();
        this.$router.push({
          path: "/sale/retailOutletList/saleOutList",
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    checkboxChange(index, val, row) {
      if (val) {
        this.productList[index].discount = 0;
        this.productList[index].isGifts = 1;
      } else {
        this.openPriceList(row, index);
      }
      //  this.totalPrice = 0;
      // this.handlePrice = 0;
      // this.productList.forEach((item) => {
      //   this.totalPrice += (item.price * item.buyNum * item.discount) / 100;
      //   this.handlePrice += item.price * item.buyNum;
      // });
      this.productList = JSON.parse(JSON.stringify(this.productList));
      this.priceSum();
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        //calcProp 为需要计算的列的prop值的集合
        if (column.property === "buyNum") {
          const values = data.map((item) => item["buyNum"]);
          sums[index] = values.reduce((prev, curr) => {
            if (curr) {
              return prev + curr;
            } else {
              return prev;
            }
          });
        }
        if (
          column.property === "price" ||
          column.property === "jine" ||
          column.property === "discountYouhui" ||
          column.property === "zhehoujine"
        ) {
          const values = data.map((item) => Number(item[column.property]));
          let num = 0;
          values.forEach((v) => {
            if (v) {
              return (num += parseFloat(v));
            } else {
              return (num += 0);
            }
          });
          sums[index] = this.backPrice((num.toFixed(3) * 1000) / 1000);
        }
      });
      return sums;
    },
    backPrice(val) {
      if (val == null || val == "NaN") {
        return "";
      }
      let num = val;
      num += "";
      num = num.replace(/[^0-9|\.]/g, "");

      if (/^0+/) num = num.replace(/^0+/, "");
      if (!/\./.test(num)) num += ".00";
      if (/^\./.test(num)) num = "0" + num;
      num += "00";
      num = num.match(/\d+\.\d{2}/)[0];
      return num;
    },
    vipPriceClick(item) {
      this.productList[this.priceDialogIndex].tempPrice = item.price / 100;
      this.productList[this.priceDialogIndex].priceType = item.label;
      this.dialogVisible = false;
      this.$set(this.productList);
      this.inputBlur();
    },
    //价格点击
    priceClick(item) {
      this.productList[this.priceDialogIndex].tempPrice = item.value;
      this.productList[this.priceDialogIndex].priceType = item.label;
      this.dialogVisible = false;
      this.$set(this.productList);
      this.inputBlur();
    },
    getUnitName(unitList, unitId) {
      let name = "";
      unitList.forEach((item) => {
        if (item.id == unitId) {
          name = item.name;
        }
      });
      return name;
    },
    GetConfig() {
      GetParameter().then((res) => {
        const { code, data, msg } = res;
        if (code === 1) {
          this.Config = { ...data };
        }
      });
    },
    unitChange(val) {
      this.tabelFlag = false;
      val.unitList.forEach((item) => {
        if (item.id == val.unitId) {
          val.tempPrice = item.price / 100;
        }
      });
      // TODO 处理选择用户后的会员价问题
      this.productList = JSON.parse(JSON.stringify(this.productList));
      this.inputBlur();
    },
    // 获取商品单位
    unitClick(val) {
      let arr = [];
      val.forEach((item) => {
        let obj = {
          value: "",
          label: "",
        };
        obj.value = item.id;
        obj.label = item.name;
        arr.push(obj);
      });
      this.unitData = arr;
    },
    // 获取价格模板
    async getTemplate() {
      let res = await getTemplate();
      if (res.code == 1) {
        console.log(res.data);
      }
    },
    // 查询价格参照
    async getPriceReference(productId, unitId) {
      this.priceList = [];
      let obj = {
        value: "",
        label: "",
      };
      let obj2 = {
        value: "",
        label: "",
      };
      let params = {
        productId,
        unitId,
      };
      let res = await getPriceReference(params);
      if (res.code == 1 && res.data) {
        if (res.data.newPurchasePrice) {
          obj.value = res.data.newPurchasePrice / 100; //最新采购价
          obj.label = "最新采购价";
          this.priceList.push(obj);
        }
        if (res.data.newOrderPrice) {
          obj2.value = res.data.newOrderPrice / 100; //最新售价
          obj2.label = "最新售价";
          this.priceList.push(obj2);
        }
      }
    },
    async openPriceList(row, index) {
      let obj = {
        value: "",
        label: "采购价",
      };
      let obj2 = {
        value: "",
        label: "零售价",
      };
      let obj3 = {
        value: "",
        label: "批发价",
      };
      await this.getPriceReference(row.id, row.unitId);
      row.unitList.forEach((item) => {
        if (item.id == row.unitId) {
          this.vipList = item.unitTemplateList;
          obj.value = item.purchasePrice / 100;
          obj2.value = item.price / 100;
          obj3.value = item.batchPrice / 100;
          this.priceList.push(obj);
          this.priceList.push(obj2);
          this.priceList.push(obj3);
        }
      });
      this.vipList[0].label = "会员等级1";
      this.vipList[1].label = "会员等级2";
      this.vipList[2].label = "会员等级3";
      // this.vipList[3].label = "会员等级4";
      // this.vipList[4].label = "会员等级5";
      this.priceDialogId = row.id;
      this.priceDialogIndex = index;
      this.dialogVisible = true;
    },
    tableRowClassName({ row, rowIndex, columnIndex, column }) {
      // 把每一行的索引放进row
      row.index = rowIndex;
    },
    handleRowClick(row, column) {
      this.clickRowIndex = row.index;
      this.tabClickLabel = column.label;
    },
    inputBlur(row) {
      this.clickRowIndex = null;
      this.tabClickLabel = "";
      this.tabelFlag = true;
      this.priceSum();
    },

    // 删除
    removeList(index) {
      // this.totalPrice = 0;
      // this.handlePrice = 0;
      this.productList.splice(index, 1);
      // this.productList.forEach((item) => {
      //   this.totalPrice += (item.price * item.buyNum * item.discount) / 100;
      //   this.handlePrice += item.price * item.buyNum;
      // });
    },
    //复制
    copyList(val, index) {
      // this.totalPrice = 0;
      // this.handlePrice = 0;
      let obj = {};
      this.productList.forEach((item) => {
        if (item.id == val) {
          obj = JSON.parse(JSON.stringify(item));
        }
      });
      this.productList.splice(index, 0, obj);
      // this.productList.forEach((item) => {
      //   this.totalPrice += (item.price * item.buyNum * item.discount) / 100;
      //   this.handlePrice += item.price * item.buyNum;
      // });
    },
    //数量变化，计算合计
    buyNumChange(val) {
      // this.totalPrice = 0;
      // this.handlePrice = 0;
      // this.productList.forEach((item) => {
      //   this.totalPrice += (item.price*100 * item.buyNum * item.discount) / 100;
      //   this.handlePrice += item.price * item.buyNum;
      // });
      this.priceSum();
      // this.getSummaries();
    },
    // checkchangeNum(val) {
    //   // this.$forceUpdate();
    //   console.log(val);
    //   this.$set(this.productList);
    // },
    // 已选商品的关闭按钮
    closeSelected() {
      this.selectFlag = true;
    },
    // 点击已选商品
    selectedProduct() {
      this.selectFlag = false;
    },
    //结算弹窗的多选框
    checkChange(val) {
      if (!val) {
        this.payForm.name = "";
      }
    },
    // 结算
    saveData() {
      if (!this.searchForm.customerId) {
        this.$message({
          type: "warning",
          message: "请选择客户",
        });
        return;
      }
      if (this.productList && this.productList.length == 0) {
        this.$message({
          type: "warning",
          message: "请加入商品",
        });
        return;
      }
      this.submitProductList = this.productList.map((item) => {
        return {
          productId: item.id,
          num: item.buyNum,
          unitId: item.unitId,
          price: item.tempPrice,
          discount: item.discount,
          discountPrice: (item.tempPrice * item.discount) / 100,
          isGifts: item.isGifts,
          remark: item.remark,
        };
      });
      // this.payShow = true;
      // 打开结算窗口
      this.openAccountDialog();
    },
    // 存草稿
    async saveCaogao() {
      this.submitProductList = this.productList.map((item) => {
        return {
          productId: item.id,
          num: item.buyNum,
          unitId: item.unitId,
          price: item.tempPrice,
          discount: item.discount,
          discountPrice: (item.tempPrice * item.discount) / 100,
          isGifts: item.isGifts,
          remark: item.remark,
        };
      });
      let total = 0;
      const accountList = this.accountList
        .filter((a) => a.price != undefined)
        .map((a) => {
          total += a.price * 100;
          return { accountId: a.id, price: a.price * 100 };
        });
      let priceTotal = 0; // 应收金额(不计算折扣)
      this.submitProductList = this.submitProductList.map((item) => {
        delete item.discountPrice;
        item.price = item.price * 100;
        priceTotal += item.price * item.num;
        return item;
      });
      let params = {
        customerId: this.searchForm.customerId,
        status: 1,
        wmsId: this.searchForm.wmsId,
        handler: this.searchForm.handler,
        entryDate: this.searchForm.time,
        no: this.searchForm.no,
        price: priceTotal,
        preferentialPrice: Number(
          ((this.handlePrice - this.totalPrice) * 100).toFixed(2)
        ), //优惠金额
        discount: this.discount, // 折扣
        handlePrice: this.handlePrice * 100, //应收金额
        thisPayment: total, //本次收款
        accountList: accountList, // 付款账户
        productList: this.submitProductList,
        prescriptionList: this.prescriptionList,
      };

      let res = await setWebOrder(params);
      if (res.code == 1) {
        this.$message({
          type: "success",
          message: res.msg,
        });
        this.resetList();
        this.$router.push({
          path: "/sale/retailOutletList/saleOutList",
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    // 获取账户
    async GetAccountList() {
      let res = await GetAccountList();
      if (res.code == 1) {
        this.accountList = res.data.records;
      }
    },
    // 整单折扣率
    discountRate() {
      this.discountShow = true;
    },
    // 清空单据
    resetList() {
      this.productList = [];
      this.discount = 0;
      this.form.name = "";
      this.prescriptionList = [];
      // 删除临时处方
      sessionStorage.removeItem("prescribes");
      // this.totalPrice = 0;
    },
    // 获取商品详情
    async getDetails(val) {
      let params = { productId: val };
      let res = await getDetails(params);
      if (res.code == 1) {
        this.proInfo.productInfo = res.data;
        this.tableData[1].list.forEach((item) => {
          if (item.id == res.data.typeId) {
            this.proInfo.productInfo.typeName = "农药/" + item.name;
          }
        });
        this.proInfo.isShow = true;
      }
    },
    //获取经手人
    async getUserCustom() {
      const userId = sessionStorage.getItem("userId");
      let res = await getUserCustom();
      if (res.code == 1) {
        this.userCustomList = res.data.records;
        this.userCustomList.forEach((item) => {
          if (userId == item.id) {
            this.searchForm.handler = item.id;
          }
        });
      }
    },
    //获取商品列表
    async getProductParamList(val) {
      let params = {
        pageNum: this.searchForm.pageNum,
        pageSize: this.searchForm.pageSize,
        wmsId: this.searchForm.wmsId,
        typeId: val,
      };
      let res = await getProductParamList(params);
      if (res.code == 1) {
        this.productData = res.data.records.map((val) => {
          val.price = val.price / 100;
          return val;
        });
        this.total = res.data.total;
      }
    },
    //获取仓库
    async GetWmsList() {
      let res = await GetWmsList();
      if (res.code == 1) {
        this.wmsList = res.data.records;
        this.wmsList.forEach((item) => {
          if (item.type == "0") {
            this.searchForm.wmsId = item.id;
          }
        });
        this.getProductParamList();
      }
    },
    //获取零售单号
    async getCode() {
      let params = { date: this.searchForm.time };
      let res = await getCode(params);
      if (res.code == 1) {
        this.searchForm.no = res.data;
      }
    },
    async addProduct(val) {
      let obj = {};
      let res = await getDetails({ productId: val.id });
      if (res.code == 1) {
        this.productDetail = res.data;
        obj = this.productDetail;
        // this.totalPrice = 0;
        // this.handlePrice = 0;
        obj.inventroyNum = obj.num;
        obj.buyNum = 0;
        obj.isGifts = 0;
        obj.remark = "";
        if (this.form.name) {
          obj.discount = this.form.name;
        } else {
          obj.discount = 100;
        }
        obj.price = val.price;
        obj.tempPrice = val.tempPrice || val.price;
        this.productList.push(obj);
        // 往原数组中添加新对象，相同就指定值+1，不相同就直接加进去
        for (let i = 0; i < this.productList.length; i += 1) {
          if (this.productList[i].id === obj.id) {
            this.productList[i].buyNum += 1;
          }
        }
        // 数组对象去重
        let map = new Map();
        let arr = [];
        for (let item of this.productList) {
          if (!map.has(item.id)) {
            map.set(item.id, item);
          }
        }
        arr = [...map.values()];
        this.productList = JSON.parse(JSON.stringify(arr));
        // this.productList.forEach((item) => {

        //   // this.totalPrice += (item.unitList[0].price * item.buyNum * item.discount) / 100;
        //   // this.handlePrice += item.price * item.buyNum;
        //   item.price = val.tempPrice || item.unitList[0].price/100;
        // });

        //将查询列表的库存放到商品详情列表
        this.productList.forEach((item) => {
          this.productData.forEach((items) => {
            if (item.id == items.id) {
              item.inventroyNum = items.num;
            }
          });
        });
        // 默认单位为unitList[0]的id
        this.productList.forEach((item) => {
          item.unitId = item.unitList[0].id;
          item.priceType = "零售价";
        });
        this.priceSum();
      }
    },
    priceSum() {
      this.productList = this.productList.map((item) => {
        item.jine = (item.buyNum * item.tempPrice).toFixed(2);
        item.zhehouPrice = ((item.discount * item.tempPrice) / 100).toFixed(2);
        item.zhehoujine = (
          (item.buyNum * item.tempPrice * item.discount) /
          100
        ).toFixed(2);
        item.discountYouhui = (
          item.buyNum * item.tempPrice -
          (item.buyNum * item.tempPrice * item.discount) / 100
        ).toFixed(2);
        return item;
      });
    },
    openProductInfo(val) {
      this.getDetails(val.id);
    },
    handleNodeClick(data) {
      this.getProductParamList(data.id);
    },
    getType() {
      GetProductTypeList(this.searcForm).then((res) => {
        if (res.code === 1) {
          const { records } = res.data;
          records.unshift({ id: "", list: [], name: "全部" });
          this.tableData = records;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    changeCurrentPage(val) {
      this.searchForm.pageNum = val;
      this.getProductParamList();
    },
    openChangePrice(list) {
      this.$confirm(
        "当前选择的商品会按照客户的预设价格重新计算, 是否继续?",
        "提示",
        {
          confirmButtonText: "按预设价格计算",
          cancelButtonText: "保留当前价格",
          type: "warning",
        }
      )
        .then(() => {
          this.changePrice(list);
        })
        .catch(() => {});
    },
    changePrice(list) {
      const customer = list[0];
      if (customer.way) {
        switch (customer.way) {
          case "1":
            // 会员价
            this.productList = this.productList.map((v) => {
              if (v.unitList) {
                v.unitList.map((u) => {
                  if (
                    u.id == v.unitId &&
                    u.unitTemplateList &&
                    u.unitTemplateList.length > 0
                  ) {
                    const tempPrice = u.unitTemplateList.filter(
                      (t) => t.templateId == customer.templateId
                    );
                    v.tempPrice = tempPrice[0].price / 100 || v.price;
                  }
                });
              }
              return v;
            });
            this.productData = this.productData.map((v) => {
              if (v.unitList) {
                v.unitList.map((u) => {
                  if (
                    u.isInit == "0" &&
                    u.unitTemplateList &&
                    u.unitTemplateList.length > 0
                  ) {
                    const tempPrice = u.unitTemplateList.filter(
                      (t) => t.templateId == customer.templateId
                    );
                    v.tempPrice = tempPrice[0].price / 100 || undefined;
                  }
                });
              }

              return v;
            });
            break;
          case "3":
            // 零售价
            this.productList = this.productList.map((v) => {
              v.tempPrice = v.price;
              return v;
            });
            this.productData = this.productData.map((v) => {
              v.tempPrice = v.price;
              return v;
            });
            break;
          case "4":
            // 批发价
            this.productList = this.productList.map((v) => {
              v.tempPrice = v.batchPrice || v.price;
              return v;
            });
            this.productData = this.productData.map((v) => {
              v.tempPrice = v.batchPrice || v.price;
              return v;
            });
            break;
          default:
            this.productList = this.productList.map((v) => {
              v.tempPrice = v.price;
              return v;
            });
            this.productData = this.productData.map((v) => {
              v.tempPrice = v.price;
              return v;
            });
            break;
        }
      }
    },
    SelectCustom(list) {
      // 处理会员预设价格
      if (
        this.searchForm.customerId !== list[0].id &&
        this.productList.length > 0 &&
        list[0].way != "0"
      ) {
        this.openChangePrice(list);
      } else {
        this.changePrice(list);
      }
      this.payment = list[0].payment;
      this.checkCustomName = list[0].name;
      this.searchForm.customerId = list[0].id;
    },
    customClick() {
      this.cusInfo.isShow = true;
      this.$refs.selectCustomRef.setSelection(this.searchForm.customerId);
    },
    SelectPrescribe(data) {
      this.prescriptionList = data;
      this.checkPrescribeName = data.map((v) => v.name).join(",");
    },
    prescribeClick() {
      this.prescribeInfo.isShow = true;
      this.$refs.selectPrescribeRef.setSelection(
        this.prescriptionList.map((v) => v.id)
      );
    },
    paySave(val) {
      this.$refs["payForm"].validate(async (valid) => {
        if (valid) {
          const resultProductList = this.productList.map((item) => {
            item.discountPrice = item.discountPrice * 100;
            item.price = item.tempPrice * 100;
            return item;
          });
          let params = {
            customerId: this.searchForm.customerId,
            status: 0,
            wmsId: this.searchForm.wmsId,
            handler: this.searchForm.handler,
            entryDate: this.searchForm.time,
            no: this.searchForm.no,
            price: this.handlePrice * 100,
            preferentialPrice: Number(
              ((this.handlePrice - this.totalPrice) * 100).toFixed(2)
            ), //优惠金额
            discount: this.discount, // 折扣
            handlePrice: this.totalPrice * 100, //应收金额
            thisPayment: this.payForm.name
              ? this.payForm.name * 100
              : this.totalPrice * 100, //本次收款
            accountId: this.accountId,
            productList: resultProductList,
            prescriptionList: this.prescriptionList,
          };
          if (!val) {
            params.thisPayment = 0;
          }
          let res = await setWebOrder(params);
          if (res.code == 1) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.resetList();
            this.$router.push({
              path: "/sale/retailOutletList/saleOutList",
            });
          }
        }
      });
    },
    payChange(val) {
      val = Number(val);
      if (!val) {
        this.payForm.name = "";
      }
    },
    discountChange(val) {
      val = Number(val);
      if (!val) {
        this.form.name = "";
      }
      if (val > 100 || val < 0) {
        this.form.name = 100;
      }
    },
    // 整单折扣率保存
    discountSave() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // this.totalPrice = this.handlePrice;
          // this.totalPrice = (this.totalPrice * this.form.name) / 100;
          this.productList.forEach((item) => {
            item.discount = this.form.name;
            item.isGifts = 0;
          });
          this.$forceUpdate();
          this.$set(this.productList);
          this.discountShow = false;
          this.discount = this.form.name;
        }
      });
    },
  },
};
</script>

<style scoped>
.priceActive {
  color: #76d3fa;
}
.priceActive2 {
  color: #c295ff;
}
.priceActive3 {
  color: #f7ae4f;
}
.priceActive4 {
  color: #31b4a8;
}
.selectedProduct {
  width: 100%;
}
.selected-title {
  height: 59px;
  line-height: 59px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
}
.footer-right {
  margin-top: 8px;
}
.grayBtn {
  width: 148px;
  height: 42px;
  background: #65728a;
  opacity: 0.8;
  border-radius: 21px;
  color: #fff;
  line-height: 42px;
  text-align: center;
  margin-left: 32px;
  cursor: pointer;
}
.saveBtn {
  width: 148px;
  height: 42px;
  background: linear-gradient(0deg, #fd9262, #f55710);
  box-shadow: 0px 3px 7px 0px rgba(248, 107, 63, 0.8);
  border-radius: 21px;
  color: #fff;
  line-height: 42px;
  text-align: center;
  margin-left: 32px;
  cursor: pointer;
}
.footer-text {
  margin-left: 23px;
  margin-right: 50px;
}
.species {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #555555;
  line-height: 60px;
  margin-right: 8px;
}
.footer-num {
  font-size: 18px;
  font-family: Arial;
  font-weight: 400;
  color: #f8503f;
  line-height: 60px;
  margin-right: 7px;
  transition: 0.5s;
}
.species-unit {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 60px;
}
.footer {
  height: 59px;
  line-height: 59px;
  border-top: 1px solid #ccc;
  margin-top: 14px;
}
.footer-button {
  width: 127px;
  height: 42px;
  line-height: 42px;
  color: #fff;
  text-align: center;
  background: linear-gradient(0deg, #7bb4fc, #3f95f2);
  box-shadow: 0px 3px 7px 0px rgba(65, 150, 243, 0.8);
  border-radius: 21px;
  cursor: pointer;
  margin-top: 9px;
}
.btn-text {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
::v-deep .el-pagination.is-background .btn-next {
  margin: 0 5px;
  background-color: #f4f4f5;
  color: #606266;
  min-width: 42px;
  min-height: 42px;
  line-height: 42px;
  border-radius: 50% !important;
}
::v-deep .el-pagination.is-background .btn-prev {
  margin: 0 5px;
  background-color: #f4f4f5;
  color: #606266;
  min-width: 42px;
  min-height: 42px;
  line-height: 42px;
  border-radius: 50% !important;
}
::v-deep .el-pagination.is-background .el-pager li {
  margin: 0 5px;
  background-color: #f4f4f5;
  color: #606266;
  min-width: 42px;
  min-height: 42px;
  line-height: 42px;
  border-radius: 50% !important;
}
::v-deep .el-tree-node:focus > .el-tree-node__content {
  width: 227px;
  height: 55px;
  background: linear-gradient(0deg, #7bb4fc, #3d94f2);
  box-shadow: 0px 2px 5px 0px rgba(70, 153, 243, 0.8);
  border-radius: 0px 6px 6px 0px;
  color: #fff;
}
::v-deep .el-tree-node__content {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 55px !important;
  line-height: 55px !important;
  background: #f8fafe;
}

::v-deep .el-tree-node {
  white-space: nowrap;
  outline: 0;
  height: 55px !important;
  line-height: 55px !important;
}
.soldOut-line {
  background: #999 !important;
}
.add-icon {
  position: absolute;
  bottom: -15px;
  right: 0;
  cursor: pointer;
}
.money {
  margin-left: 12px;
  position: relative;
  color: #f8503f;
}
.money-icon {
  font-size: 16px;
}
.money-num {
  font-size: 22px;
  font-weight: 500;
}
.item-desc {
  position: relative;
  height: 70px;
}
.limit-img {
  position: absolute;
  top: 0;
  right: 15px;
}
.con-no {
  font-size: 14px;
  font-family: Arial;
  font-weight: normal;
  color: #333333;
}
.con-num {
  font-size: 16px;
  font-family: Arial;
  font-weight: normal;
  color: #333333;
}
.con-wid {
  width: 71px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.con-inventroy {
  height: 32px;
  line-height: 32px;
}
.item-icon {
  margin: 10px 8px 0 12px;
}
.icon-circle {
  width: 8px;
  height: 8px;
  background: #d6e7fa;
  border-radius: 50%;
}
.icon-line {
  width: 1px;
  height: 26px;
  border: 1px solid #eceff9;
  margin-left: 2px;
}
.proContent {
  height: 67vh;
}
.con-left {
  width: 215px;
  height: 67vh;
  background: #f8fafe;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 4px 10px 0px rgba(230, 231, 232, 0.54);
  border-radius: 6px;
  margin-right: 20px;
  overflow-x: hidden;
  overflow-y: auto;
}
.con-right {
  flex: 1;
  height: 67vh;
  flex-wrap: wrap;
}
.right-item {
  width: 308px;
  height: 172px;
  background: #ffffff;
  box-shadow: 0px 3px 7px 0px rgba(201, 220, 242, 0.8);
  border-radius: 15px;
  margin-bottom: 25px;
  margin-right: 34px;
  list-style: none;
}
.bigbox {
  height: 64vh;
}
.bigbox li:nth-child(4n) {
  margin-right: 0px !important;
}
.right-line {
  width: 308px;
  height: 15px;
  background: #4f9cf3;
  opacity: 0.2;
  border-radius: 10px 10px 0px 0px;
}
.item-title {
  margin-bottom: 3px;
  line-height: 45px;
}
.item-name {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #222222;
  margin-left: 12px;
}
.item-no {
  font-size: 14px;
  font-family: Arial;
  font-weight: normal;
  color: #111111;
  margin-right: 15px;
}
.sum {
  display: inline-block;
  width: 83px;
  text-align: left;
  padding-left: 28px;
  margin-bottom: 20px;
}
.textbold {
  font-weight: bold !important;
}
.el-dialog__body {
  padding: 15px 20px !important;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}
.proName {
  line-height: 30px;
}
.proUnit {
  line-height: 30px;
}
.priceItem {
  width: 23%;
  height: 60px;
  border: 1px solid #facd91;
  text-align: center;
  line-height: 20px;
  margin-top: 15px;
  cursor: pointer;
}
.price {
  margin-top: 13px;
  font-style: 18px;
  font-weight: bold;
}
.priceType {
  font-size: 12px;
  color: #999;
}
.line {
  width: 4px;
  height: 20px;
  background-color: #35c093;
  margin-right: 10px;
}
.F {
  display: flex;
}
.FS {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}
</style>
