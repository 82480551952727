<template>
  <div>
    <el-dialog title="商品信息" :visible.sync="info.isShow" width="55%">
      <div class="F tip">
        <div class="line"></div>
        <div>基础信息</div>
      </div>
      <el-descriptions
        class="margin-top"
        title=""
        size="samll"
        :column="2"
        border
      >
        <el-descriptions-item>
          <template slot="label"> 类别 </template>
          {{info.productInfo.typeName}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 商品名称 </template>
          <div class="FSB">
            <div>{{ info.productInfo.name }}</div>
            <div class="smallMark" v-if="info.productInfo.isLimit == '1'">
              限用
            </div>
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 登记证号 </template>
          {{ info.productInfo.regCode }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 商品编码 </template>
          {{ info.productInfo.code }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 条形码 </template>
          {{ info.productInfo.barCode ? info.productInfo.barCode : "-" }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 规格 </template>
          {{ info.productInfo.spec ? info.productInfo.spec : "-" }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 毒性 </template>
          {{ info.productInfo.toxicity ? info.productInfo.toxicity : "-" }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 剂型 </template>
          {{ info.productInfo.dosage ? info.productInfo.dosage : "-" }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 生产企业 </template>
          {{
            info.productInfo.manufacturer ? info.productInfo.manufacturer : "-"
          }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 生产许可证号 </template>
          {{ info.productInfo.license ? info.productInfo.license : "-" }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 有效成分及含量 </template>
          {{
            info.productInfo.effectiveContent
              ? info.productInfo.effectiveContent
              : "-"
          }}
        </el-descriptions-item>
      </el-descriptions>
      <div class="F tip">
        <div class="line"></div>
        <div>价格信息</div>
      </div>
      <el-table :data="info.productInfo.unitList" style="width: 100%" border>
        <el-table-column prop="name" label="单位名称" align="center">
        </el-table-column>
        <el-table-column prop="price" label="零售价" align="center">
          <template slot-scope="scope">
            {{ scope.row.price!=undefined ? (scope.row.price/100).toFixed(2) : "0.00" }}
          </template>
        </el-table-column>
        <el-table-column prop="batchPrice" label="批发价" align="center">
          <template slot-scope="scope">
            {{
              scope.row.batchPrice!=undefined ? (scope.row.batchPrice/100).toFixed(2) : "0.00"
            }}
          </template>
        </el-table-column>
          <el-table-column prop="name" label="会员等级1" align="center">
            <template slot-scope="scope">
              {{scope.row.unitTemplateList[0].price!=undefined ?(scope.row.unitTemplateList[0].price/100).toFixed(2):'0.00'}}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="会员等级2" align="center">
            <template slot-scope="scope">
              {{scope.row.unitTemplateList[1].price!=undefined?(scope.row.unitTemplateList[1].price/100).toFixed(2):'0.00'}}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="会员等级3" align="center">
            <template slot-scope="scope">
              {{scope.row.unitTemplateList[2].price!=undefined?(scope.row.unitTemplateList[2].price/100).toFixed(2):'0.00'}}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="name" label="会员等级4" align="center">
            <template slot-scope="scope">
              {{scope.row.unitTemplateList[3].price?scope.row.unitTemplateList[3].price.toFixed(2):'0.00'}}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="会员等级5" align="center">
            <template slot-scope="scope">
              {{scope.row.unitTemplateList[4].price?scope.row.unitTemplateList[4].price.toFixed(2):'0.00'}}
            </template>
          </el-table-column> -->
        <el-table-column prop="name" label="本次数量" align="center">
          <!-- <template slot-scope="scope">
              {{scope.row.unitTemplateList[2].price?scope.row.unitTemplateList[2].price.toFixed(2):'0.00'}}
            </template> -->
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template>
            <el-button type="text">加入购物车</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tableData: [],
    };
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.smallMark {
  color: #fff;
  background: #f00;
  padding: 0px 5px;
  font-size: 12px;
  height: 18px;
  margin-top: 3px;
}
.tip {
  height: 40px;
  line-height: 40px;
  font-weight: bold;
  margin-bottom: 10px;
}
.line {
  height: 20px;
  width: 4px;
  background: #35c093;
  margin-top: 10px;
  margin-right: 10px;
}
.F {
  display: flex;
}
.FSB {
  display: flex;
  justify-content: space-between;
}
</style>