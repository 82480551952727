<template>
   <el-dialog title="新增处方" :visible.sync="info.isShow" width="40%">
    
    <div class="content">
     
          <el-form
            ref="basicForm"
            :model="form"
            :rules="FormRules"
            label-width="140px"
          >
            <el-row>
              <el-col :span="11">
                <el-form-item label="处方名称" prop="name">
                  <el-input
                    v-model="form.name"
                    placeholder="请填写处方名称"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
             <el-row>
              <el-col :span="16">
                <el-form-item label="处方说明" prop="name">
                  <el-input
                  type="textarea"
                    v-model="form.description"
                    placeholder="请填写处方说明"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
            <el-form-item label="是否存为模板">
                <el-switch v-model="form.type"></el-switch>
              </el-form-item>
            </el-row>
       
          </el-form>
    
    </div>
     <span slot="footer" class="dialog-footer">
        <el-button @click="info.isShow = false">取 消</el-button>
        <el-button type="primary" @click="submitData">确 定</el-button>
      </span>
   </el-dialog>
</template>
<script>
import {
 getCode,
 AddPrescribe
} from "@/api/prescribe";
export default {
   props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
   
    return {
     
      loading: false,
      id: "",
      
      
      form: {
        name: "",
       description:"",
       type:false
      },
      
      FormRules: {
        name: [
          { required: true, message: "请填写处方名称", trigger: "blur" },
          {
            min: 1,
            max: 64,
            message: "名称长度在 1 到 64 个字符",
            trigger: "blur",
          },
        ],
        description: [
          {
            min: 1,
            max: 255,
            message: "名称长度在 1 到 255 个字符",
            trigger: "blur",
          },
        ]
      },
      total: 0,
    };
  },
  created() {
  },
  mounted() {
    // this.$refs["basicForm"].resetFields();
  },
  methods: {
   
   
    back() {
      this.$emit("back");
    },
    // 保存
    submitData() {
      this.$refs["basicForm"].validate((valid) => {
        if (valid) {
             const result = JSON.parse(JSON.stringify(this.form));
             result.type = result.type?'0':'1';
              AddPrescribe(result).then((res) => {
                const { code, msg } = res;
                if (code === 1) {
                  // 保存成功，存储在storage里面
                  const prescribes = JSON.parse(sessionStorage.getItem("prescribes")) || [];
                  prescribes.push({...result,id:res.data});
                  sessionStorage.setItem("prescribes",JSON.stringify(prescribes));
                  this.$message.success(msg);
                  this.info.isShow = false;
                  this.$emit('data');
                } else {
                  this.$message.error(msg);
                }
              });
        } else {
          return false;
        }
      });
    },
  },
};
</script>